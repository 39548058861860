export enum DownloadFormFieldNames {
  firstName = "firstName",
  lastName = "lastName",
  companyName = "companyName",
  email = "email",
  agreement = "agreement"
}

export interface DownloadFormProps {
  content?: JSX.Element;
  agreement?: JSX.Element;
  successMessage?: JSX.Element;
  ctaLabel?: string;
  sendGridId?: string;
  customFieldId?: string;
  fileType?: string;
  primaryImage?: string;
  secondaryImage?: string;
  useAlternativeBackground?: boolean;
  labelOpacity?: number;
  labelColor?: string;
}
