import React from "react";
import {
  Box,
  FlexBox
} from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import { FormattedMessage, useIntl } from "react-intl";

import Checkbox from "@components/atoms/fields/Checkbox";

import * as Styled from "./styles";
import Cover from "./Partials/Cover";
import { useDownloadForm } from "./hooks";
import { DownloadFormFieldNames, DownloadFormProps } from "./types";
import Modal from "./Partials/Modal";

const DownloadForm: React.FC<DownloadFormProps> = ({
  content,
  agreement,
  ctaLabel,
  successMessage,
  sendGridId,
  customFieldId,
  labelOpacity,
  labelColor,
  ...coverProps
}) => {
  const { formatMessage } = useIntl();
  const {
    status,
    formRef,
    isButtonEnabled,
    handleOnSubmit,
    onClose
  } = useDownloadForm(sendGridId, customFieldId);

  return (
    <>
      <FlexBox
        flexDirection="column"
        alignItems="center"
        mx="auto"
        maxWidth={["none", 660]}
        gap="2rem"
        className="ga-filedownload-viewed"
      >
        {coverProps?.primaryImage && (
          <Box display={["block", "none"]} pt={330} />
        )}

        {content}
        <Styled.Form onSubmit={handleOnSubmit} ref={formRef}>
          <FlexBox
            alignItems="center"
            flexDirection="column"
            mb="0.75rem"
            gap="0.5rem"
          >
            <FlexBox
              width="100%"
              gap="1rem"
              flexDirection={["column", "row"]}
            >
              <Styled.InputField
                name={DownloadFormFieldNames.email}
                type="email"
                required
                label={formatMessage({
                  defaultMessage: "Company e-mail"
                })}
                {...{ labelOpacity, labelColor }}
              />
              <Styled.InputField
                name={DownloadFormFieldNames.companyName}
                type="text"
                pattern=".*\S.*"
                label={formatMessage({
                  defaultMessage: "Company name"
                })}
                {...{ labelOpacity, labelColor }}
              />
            </FlexBox>
            <FlexBox
              width="100%"
              gap="1rem"
              flexDirection={["column", "row"]}
            >
              <Styled.InputField
                name={DownloadFormFieldNames.firstName}
                type="text"
                pattern=".*\S.*"
                label={formatMessage({
                  defaultMessage: "First name"
                })}
                {...{ labelOpacity, labelColor }}
              />
              <Styled.InputField
                name={DownloadFormFieldNames.lastName}
                type="text"
                pattern=".*\S.*"
                label={formatMessage({
                  defaultMessage: "Last name"
                })}
                {...{ labelOpacity, labelColor }}
              />
            </FlexBox>

            <Styled.SubmitButton
              type="submit"
              height="3rem"
              width={["100%", "auto"]}
              mt="1rem"
              disabled={!isButtonEnabled}
              className="ga-cta-filedownload"
            >
              {ctaLabel || (
                <FormattedMessage defaultMessage="Download" />
              )}
            </Styled.SubmitButton>
          </FlexBox>
          <FlexBox maxWidth={["none", 440]}>
            <Checkbox
              name={DownloadFormFieldNames.agreement}
              required
            >
              {agreement}
            </Checkbox>
          </FlexBox>
        </Styled.Form>
        {coverProps?.primaryImage && (
          <Box display={["none", "block"]} pt={150} />
        )}
      </FlexBox>
      <Cover {...coverProps} />
      {status && <Modal {...{ status, successMessage, onClose }} />}
    </>
  );
};

export default DownloadForm;
