import styled from "styled-components";
import Button from "@pagepro-monorepo/ui/lib/components/atoms/Button";

import Input from "@components/atoms/fields/Input";

export const InputField = styled(Input)`
  width: 100%;
`;

export const SubmitButton = styled(Button)`
  min-width: max-content;
`;

export const Form = styled.form`
  width: 100%;
`;
