import React from "react";
import {
  StoryblokComponent,
  storyblokEditable
} from "@storyblok/react";

import DownloadForm from "@components/organisms/forms/DownloadForm";
import { tryToReplaceDomain } from "@utils/tryToReplaceDomain";

import { DownloadFormStoryblokProps } from "./types";

const DownloadFormStoryblok: React.FC<DownloadFormStoryblokProps> = ({
  blok
}) => {
  const {
    _uid: key,
    content,
    agreement,
    ctaLabel,
    sendGridId,
    primaryImage,
    secondaryImage,
    fileType,
    successMessage,
    useAlternativeBackground,
    sendGridCustomFieldId,
    labelOpacity,
    labelColor
  } = blok;

  const item = {
    key,
    content: content?.[0] ? (
      <StoryblokComponent blok={content?.[0]} />
    ) : undefined,
    agreement: agreement?.[0] ? (
      <StoryblokComponent blok={agreement?.[0]} />
    ) : undefined,
    successMessage: successMessage?.[0] ? (
      <StoryblokComponent blok={successMessage?.[0]} />
    ) : undefined,
    primaryImage: tryToReplaceDomain(primaryImage?.filename ?? ""),
    secondaryImage: tryToReplaceDomain(
      secondaryImage?.filename ?? ""
    ),
    customFieldId: sendGridCustomFieldId,
    sendGridId,
    ctaLabel,
    fileType,
    useAlternativeBackground,
    labelOpacity,
    labelColor: labelColor?.color
  };

  return (
    <DownloadForm
      {...{
        ...item,
        ...storyblokEditable(blok)
      }}
    />
  );
};

export default DownloadFormStoryblok;
